<template>
  <div class="contact-us">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="form-outer">
          <Card>
            <FormulateForm
              class="form"
              name="contact"
              v-model="formValues"
              @submit="handleSubmit"
              #default="{isLoading}"
              autocomplete="false"
            >
              <div class="row">
                <div class="red-box">
                  <h3>
                    If you're looking for your tickets, click
                    <router-link to="my-tickets">here</router-link>. Also, for
                    more frequently asked questions click
                    <router-link to="faq">here</router-link>
                  </h3>
                </div>

                <FormulateInput
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Name"
                  validation="required"
                  autocomplete="false"
                />
                <FormulateInput
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  validation="email|required"
                  autocomplete="false"
                />
                <FormulateInput
                  type="text"
                  name="event"
                  label="Event Name"
                  placeholder="Event Name"
                  validation="required"
                  autocomplete="false"
                />
                <FormulateInput
                  type="tel"
                  name="number"
                  label="Phone Number"
                  placeholder="(204) 000 - 0000"
                  validation="required"
                  :validation-messages="{
                    min: 'Invalid phone number provided.',
                  }"
                  autocomplete="false"
                />
              </div>
              <FormulateInput
                type="select"
                name="reason"
                label="Reason for Contact"
                placeholder="Reason for Contact"
                validation="required"
                :options="options"
                autocomplete="false"
              />
              <FormulateInput
                type="textArea"
                class="message"
                name="message"
                label="Message"
                placeholder="Message"
                validation="required"
                autocomplete="false"
              />
              <FormulateInput
                type="submit"
                name="Send Message"
                :loading="isLoading"
              />
            </FormulateForm>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
a {
  color: white;
  text-decoration: underline;
}
#Content .content-inner .row .col {
  margin: 0;
}
#Content .content-inner .row {
  margin: 0;
  width: initial !important;
}

#Content .content-inner {
  padding: 25px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 80vh;
  justify-content: space-between;

  section {
    margin: 0;
  }
}
.contact-us {
  .form-outer {
    // margin: auto;
    width: 100%;
    // margin-right: 20px;
    // max-width: 1100px;

    .form {
      margin: auto;
      .red-box {
        h3 {
          color: white;
          padding: 12px;
          border-radius: 10px;
          background-image: linear-gradient(#e60022, #b40621);
          /* margin-left: 1em; */
          font-size: 1.1em;
          font-weight: 500;
          line-height: 1.4;
          word-spacing: 0.025em;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }
        @media only screen and (max-width: 730px) {
          h3 {
            font-size: 12px;
          }
        }
      }
      // margin-top: 30px;
      // max-width: 602px;

      .button-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .pco {
    width: 34%;

    h3 {
      text-align: center;
      color: white;
      // font-weight: bold;
    }

    img {
      width: 100%;
      max-width: 400px;
    }

    .button-wrapper {
      text-align: center;
      margin-top: 20px;
    }
  }
  .map-cont {
    border-radius: 5px;
    margin-top: 30px;
    width: 100%;
    height: 350px;
    .map {
      border-radius: inherit;
    }
  }
  @media only screen and (max-width: 900px) {
    .form-outer {
      width: 100%;
    }
    .pco {
      width: 100%;
      img {
        // width: initial;
        margin: auto;
        display: block;
      }
    }
    .map-cont {
      height: 300px;
    }
  }
}
.message {
}
</style>

<script>
import ModalContent from "@/helpers/modals";

export default {
  name: "contact-us",
  head() {
    return {
      title: "Manage Events & Sell Tickets Online • Contact Us • INCREDEVENT",
      meta: [
        {
          name: "description",
          content:
            "INCREDEVENT is always a message away. Let's talk event creation, ticket selling, partnership opportuntities, upcoming events, or anything else!",
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion",
        },
        {
          name: "author",
          content: "INCREDEVENT",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
      ],
    };
  },
  data() {
    return {
      options: [
        { label: "", value: "" }, // empty option indicates no selection by default
        { label: "General", value: "General" },
        { label: "Media/Press", value: "Media/Press" },
        { label: "Partnerships", value: "Partnerships" },
        { label: "Sponsorships", value: "Sponsorships" },
      ],
      formValues: {
        name: "",
        email: "",
        event: "",
        number: "",
        reason: "",
        message: "",
      },
    };
  },
  watch: {
    "formValues.email"(newVal) {
      this.removeSpaces(newVal, "onlyemail");
    },
  },
  methods: {
    removeSpaces(val, type) {
      if (val) {
        switch (type) {
          case "onlyemail":
            this.formValues.email = val.replace(/\s/g, "");
            break;
          default:
            break;
        }
      }
    },
    async handleSubmit(values) {
      this.$axios.post("/emails/contact-us", values).then((response) => {
        if (response.error) {
          this.$root.alert.open({
            title: "Error",
            msg: response.error,
          });
        } else {
          this.$bus.$emit("alert", ModalContent.contactSuccess);
          this.$formulate.reset("contact");
          this.$bus.$emit("reset-phone-field");
        }
      });
    },
  },
  created() {
    this.$store.commit("setTitle", "Contact Us");
  },
};
</script>
